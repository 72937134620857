html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/nunito-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/nunito-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/nunito-extrabold.ttf') format('truetype');
}
